div.notDiagram {
  text-align: center;
}

rect:hover {
  cursor: pointer;
}

rect {
  stroke: black;
  stroke-width: 2px;
}

text {
  user-select: none;
  -moz-user-select: none; /* For Firefox */
  -webkit-user-select: none; /* For WebKit-based browsers (Chrome, Safari, etc.) */
  -ms-user-select: none; /* For Internet Explorer */
}

@media (min-width: 768px) {
  /* Apply styles for screens with a width of 768 pixels or more */
  .notDiagram rect {
      width: 130px;
      height: 70px;
  }
  .notDiagram {
    margin-left: 10%;
    margin-top: 2rem;
  }
}