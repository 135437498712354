.arrow-button {
    background-color: #3987a1; /* Pastel Blue Color */
    color: #ffffff; /* Text color */
    padding: 10px 20px; /* Padding around the text */
    border: none; /* Remove the button border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Cursor style on hover */
    font-size: 16px; /* Text size */
}

.arrow-button:hover {
    background-color: #629da9; /* Darker shade when hovering */
}

.arrows {
  text-align: center;
}

.leftArrow, .rightArrow {
  display: inline-block;
  width: 50%; /* Adjust the width as needed */
}

.arrow-button:disabled {
    background-color: #c0c0c0; /* Light gray */
    color: #888;
    cursor: not-allowed; /* Change cursor to 'not-allowed' */
}